export const levelUpData = [
    { level: 1, taps: 20 },
    { level: 2, taps: 56 },
    { level: 3, taps: 121 },
    { level: 4, taps: 238 },
    { level: 5, taps: 448 },
    { level: 6, taps: 826 },
    { level: 7, taps: 1506 },
    { level: 8, taps: 2730 },
    { level: 9, taps: 4934 },
    { level: 10, taps: 8901 },
    { level: 11, taps: 16042 },
    { level: 12, taps: 28896 },
    { level: 13, taps: 52032 },
    { level: 14, taps: 93678 },
    { level: 15, taps: 168641 },
    { level: 16, taps: 303574 },
    { level: 17, taps: 546453 },
    { level: 18, taps: 983635 },
    { level: 19, taps: 1770563 },
    { level: 20, taps: 3187034 },
    { level: 21, taps: 5736681 },
    { level: 22, taps: 10326046 },
    { level: 23, taps: 18586903 },
    { level: 24, taps: 33456446 },
    { level: 25, taps: 60221623 },
    { level: 26, taps: 108398941 },
    { level: 27, taps: 195118115 },
    { level: 28, taps: 351212626 },
    { level: 29, taps: 632182747 },
    { level: 30, taps: 1137928965 },
    { level: 31, taps: 2048272157 },
    { level: 32, taps: 3686889903 },
    { level: 33, taps: 6636401846 },
    { level: 34, taps: 11945523342 },
    { level: 35, taps: 21501942036 },
    { level: 36, taps: 38703495686 },
]
