import React, { useState, useCallback, useEffect } from 'react'
import './CharacterSelection.css'
import infoIcon from 'images/icons/info_button.png'
import { sounds } from 'utils/sounds'
import clsx from 'clsx'
import InformationSection from 'components/InformationSection/InformationSection'
import { characters } from 'constants/characters'

const ArrowButton = React.memo(({ direction, onClick, isDisabled, isAllCharactersViewed }) => {
    return (
        <button
            type="button"
            className={clsx(`action-${direction}-arrow`, { scaled: !isAllCharactersViewed && direction === 'right' })}
            onClick={onClick}
            disabled={isDisabled}
        />
    )
})

const SelectButton = React.memo(({ onClick, isDisabled }) => {
    return (
        <button type="button" className="btn-select" onClick={onClick} disabled={isDisabled}>
            Select
        </button>
    )
})

const CharacterSelection = ({ toggleShowBackdrop, handleSelectClick, step, handleNextStep, hidden }) => {
    const [selectedCharacter, setSelectedCharacter] = useState(0)

    const [viewedCharacters, setViewedCharacters] = useState(new Set())
    const [showPopup, setShowPopup] = useState(false)
    const [popupContent, setPopupContent] = useState('')
    const [hasViewedAllCharacters, setHasViewedAllCharacters] = useState(false)
    const [popupButtonText, setPopupButtonText] = useState('')

    useEffect(() => {
        setHasViewedAllCharacters(viewedCharacters.size === characters.length)
    }, [viewedCharacters])

    useEffect(() => {
        if (hasViewedAllCharacters && step === 3) handleNextStep()
    }, [handleNextStep, hasViewedAllCharacters, step])

    const handleInfoClick = useCallback(() => {
        setPopupContent(
            <div className="popup-text-content">
                <p className="popup_title_main">Pick Your Unique POOMB PET and Start Earning $COGA Tokens!</p>
                <p className="popup_title_secondary">
                    Choose wisely and begin your journey in the Poomb Creatures ecosystem—where fun meets rewards.{' '}
                </p>
                <p className="popup_title_secondary">Start farming and explore a world of endless possibilities! </p>
                <p className="popup_title_secondary">Joy-enjoy, Poomb-Poomb!</p>
            </div>,
        )
        setPopupButtonText("LET'S GO")
        setShowPopup(true)
    }, [])

    const handleClosePopup = useCallback(() => setShowPopup(false), [])

    const handleNextSlide = useCallback(() => {
        sounds.change.play()

        if (step === 3) {
            toggleShowBackdrop()
        }
        setSelectedCharacter(prev => {
            const nextIndex = (prev + 1) % characters.length
            setViewedCharacters(prevViewed => new Set(prevViewed).add(nextIndex))
            return nextIndex
        })
    }, [step, toggleShowBackdrop])

    const handlePrevSlide = useCallback(() => {
        sounds.change.play()
        setSelectedCharacter(prev => {
            const prevIndex = (prev - 1 + characters.length) % characters.length
            setViewedCharacters(prevViewed => new Set(prevViewed).add(prevIndex))
            return prevIndex
        })
    }, [])

    return (
        <div className={clsx('page', { 'character-selection-wrapper--hidden': hidden })}>
            <div className="container">
                <div className={clsx('character-selection-wrapper')}>
                    <div className="info-left-icon" onClick={handleInfoClick}>
                        <img src={infoIcon} alt="Info Icon" className="info-icon" />
                    </div>

                    <div className="select-character-wrapper">
                        <div className="character-item-slider">
                            {characters.map((character, index) => {
                                const isPrev = (selectedCharacter - 1 + characters.length) % characters.length === index
                                const isCenter = selectedCharacter === index
                                const isNext = (selectedCharacter + 1) % characters.length === index

                                return (
                                    <div
                                        key={character.name}
                                        className={`pet-item ${isCenter ? 'center' : ''} ${isPrev ? 'prev' : isNext ? 'next' : ''}`}
                                        style={{
                                            transform: isCenter ? 'scale(1.1)' : 'scale(0.9)',
                                            display: isCenter || isPrev || isNext ? 'block' : 'none',
                                        }}
                                    >
                                        {isCenter && (
                                            <div>
                                                <div className="character-label">{character.displayName}</div>
                                                <p className="character_description">{character.description}</p>
                                            </div>
                                        )}

                                        <img
                                            src={character.imageSrc}
                                            alt={character.displayName}
                                            className={`character-image ${isCenter ? 'large' : ''}`}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className="action-select-wrapper">
                        <ArrowButton direction="left" onClick={handlePrevSlide} isDisabled={!hasViewedAllCharacters} />
                        <SelectButton
                            onClick={() => handleSelectClick(characters[selectedCharacter].name)}
                            isDisabled={!hasViewedAllCharacters}
                        />
                        <ArrowButton direction="right" onClick={handleNextSlide} isAllCharactersViewed={hasViewedAllCharacters} />
                    </div>
                </div>
            </div>

            {showPopup && <InformationSection onClose={handleClosePopup} content={popupContent} buttonText={popupButtonText} />}
        </div>
    )
}

export default CharacterSelection
