export const characters = [
    {
        name: 'main',
        displayName: 'Tiger',
        description: 'THIS PASSIONATE AND COMPETITIVE PERSONALITY IS PRONE TO OUTBURSTS OF ANGER AND FRUSTRATION',
        imageSrc: require('assets/gif_characters/Tiger_Idle-min.gif'),
    },
    {
        name: 'pet1',
        displayName: 'Tarsius',
        description: 'THIS PASSIONATE AND COMPETITIVE PERSONALITY IS PRONE TO OUTBURSTS OF ANGER AND FRUSTRATION',
        imageSrc: require('assets/gif_characters/Tarsius_Idle-min.gif'),
    },
    {
        name: 'pet2',
        displayName: 'Peacock',
        description: 'THIS PASSIONATE AND COMPETITIVE PERSONALITY IS PRONE TO OUTBURSTS OF ANGER AND FRUSTRATION',
        imageSrc: require('assets/gif_characters/Peafowl_Idle-min.gif'),
    },
    {
        name: 'pet3',
        displayName: 'Capybara',
        description: 'THIS PASSIONATE AND COMPETITIVE PERSONALITY IS PRONE TO OUTBURSTS OF ANGER AND FRUSTRATION',
        imageSrc: require('assets/gif_characters/Capybara_Idle-min.gif'),
    },
    {
        name: 'pet4',
        displayName: 'Monkey',
        description: 'THIS PASSIONATE AND COMPETITIVE PERSONALITY IS PRONE TO OUTBURSTS OF ANGER AND FRUSTRATION',
        imageSrc: require('assets/gif_characters/Monkey_Idle-min.gif'),
    },
]
