import React, { useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react'
import { nanoid } from 'nanoid'
import useWebSocket from 'react-use-websocket'

import clsx from 'clsx'

import './Gameplay.css'

import coinGame from 'images/icons/autotap.png'
import coinImg from 'images/icons/coga_token.png'

import Hand from 'assets/Hand.png'

import { sounds, startInterval, stopInterval, formatValue } from 'utils'

import { maxEnergyValue, characterImages } from 'constants'
import upgradesTestArray from 'constants/uogradesTutorialTestArray'

import FlyingCoins from 'components/FlyingCoins/FlyingCoins'
import Storage from 'components/Storage/Storage'
import EnergyTimer from 'components/EnergyTimer/EnergyTimer'
import FlyingShapes from 'components/FlyingShapes/FlyingShapes'
import CoinAnimation from 'components/CoinAnimation/CoinAnimation'
import AchieveMenu from 'components/AchieveMenu/AchieveMenu'
import InfoMenu from 'components/InfoMenu/InfoMenu'
import LevelInfo from 'components/LevelInfo/LevelInfo'
import rageModeData from 'constants/rageModeData'
import LvlProgress from 'components/LvlProgress/LvlProgress'
import BottomMenu from 'components/BottomMenu/BottomMenu'
import { FaLaptopHouse } from 'react-icons/fa'

// import myVideo from 'assets/test/img.webm'

const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL

const Gameplay = ({ player, selectedCharacter, tutorial = false, step = null, handleNextStep = null }) => {
    const [isPopupVisible, setPopupVisible] = useState(false)
    const [isInfoMenuVisible, setInfoMenuVisible] = useState(step === 15 ? true : false)
    const [isAchieveMenuVisible, setAchieveMenuVisible] = useState(false)
    const [coins, setCoins] = useState(player?.tokens_earned || 0)
    const [displayedCoins, setDisplayedCoins] = useState(coins)
    const [energy, setEnergy] = useState(null)
    const [storageTokens, setStorageTokens] = useState(player?.soft_tokens ?? tutorial ? 25000 : 0)
    const [playerUpgrades, setPlayerUpgrades] = useState([])
    const [coinAnimations, setCoinAnimations] = useState([])
    const [shapeAnimations, setShapeAnimations] = useState([])
    const [isCoinAnimating, setIsCoinAnimating] = useState(false)
    const [flyingCoin, setFlyingCoin] = useState(null)
    const [flyingCoins, setFlyingCoins] = useState([])
    const [activeImage, setActiveImage] = useState(0)
    const [tapPower, setTapPower] = useState(1)
    const [levelProgress, setLevelProgress] = useState({
        clicks_needed_for_current_lvl: 0,
        clicks_to_next_lvl: 20,
        player_total_clicks: 0,
        current_lvl: player?.player_lvl ?? 4,
    })
    const [storageUpdTime, setStorageUpdTime] = useState(null)

    const [nextUpdateTime, setNextUpdateTime] = useState(null)

    // Rage mode state
    const [isRageMode, setIsRageMode] = useState(false)
    // array for Rage mode calculation
    const clickTimesRef = useRef([])

    const totalClicks = useRef(0)
    const sentClicks = useRef(0)

    const timerRef = useRef(null)
    const energyIntervalRef = useRef(null)

    const clickCount = useRef(0)
    const prevCoins = useRef(coins)
    const isCharacterAnimatingRef = useRef(false)
    const activeTouches = useRef(new Set())

    const initialCoins = parseFloat(sessionStorage.getItem('coins')) || player?.tokens_earned || 0
    const initialEnergy = parseFloat(sessionStorage.getItem('energy')) || energy || maxEnergyValue

    const { sendMessage, lastMessage } = useWebSocket(WEBSOCKET_URL, {
        shouldReconnect: () => true,
    })

    useLayoutEffect(() => {
        if (tutorial && step >= 7 && step <= 15) {
            setCoins(11)
            return
        }
        if ((tutorial && step === 5) || step === 6) {
            setEnergy(100)
            setCoins(0)
            return
        }

        setCoins(initialCoins)
        setEnergy(initialEnergy)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (step !== 15) {
            setInfoMenuVisible(false)
        }
    }, [step])

    useEffect(() => {
        sessionStorage.setItem('coins', coins)
        sessionStorage.setItem('energy', energy)
    }, [coins, energy])

    useEffect(() => {
        const levelDataRequest = {
            operation: 'get_player_level',
            data: {
                telegram_id: player?.telegram_id,
            },
        }

        const requestPlayerEnergy = () => {
            const requestData = {
                operation: 'read_player_energy',
                data: {
                    telegram_id: player.telegram_id,
                },
            }
            sendMessage(JSON.stringify(requestData))
        }

        if (lastMessage !== null) {
            try {
                const data = JSON.parse(lastMessage.data)
                switch (data.operation) {
                    case 'player_click':
                        const { tokens_earned } = data
                        setCoins(tokens_earned)
                        sendMessage(JSON.stringify(levelDataRequest))
                        requestPlayerEnergy()
                        break

                    case 'read_player':
                        const { tokens_earned: newTokens } = data.player
                        setCoins(newTokens)
                        sendMessage(JSON.stringify(levelDataRequest))
                        break

                    case 'read_player_soft_tokens':
                        const { player_soft_tokens, next_update_time: nextUpdStorageTime } = data
                        setStorageTokens(player_soft_tokens)
                        setStorageUpdTime(nextUpdStorageTime)
                        break

                    case 'read_player_energy':
                        const { available_energy, next_update_time } = data
                        setEnergy(available_energy)
                        setNextUpdateTime(next_update_time)
                        break

                    case 'get_player_upgrades':
                        const { upgrades } = data
                        const tapUpgrade = upgrades.find(upgrade => upgrade.upgrade_type === 'tap_power')
                        setPlayerUpgrades(upgrades)
                        setTapPower(tapUpgrade.current_power)
                        break

                    case 'get_player_level':
                        const { clicks_needed_for_current_lvl, clicks_to_next_lvl, player_total_clicks, current_lvl } = data
                        setLevelProgress(prevState => ({
                            clicks_needed_for_current_lvl,
                            clicks_to_next_lvl,
                            current_lvl,
                            player_total_clicks:
                                prevState.player_total_clicks > player_total_clicks ? prevState.player_total_clicks : player_total_clicks,
                        }))
                        break

                    case 'convert_soft_tokens':
                        const { tokens_earned: newTokensAfterStorageConverted, soft_tokens: softTokensAfterStorageConverted } = data

                        setStorageTokens(softTokensAfterStorageConverted)
                        setCoins(newTokensAfterStorageConverted)
                        break

                    case 'activate_rage_mode':
                        const { status } = data
                        if (status === 'success') {
                            setIsRageMode(true)
                            sounds.approve.play()

                            // Save the next activation time in sessionStorage
                            const nextRageModeTime = Date.now() + rageModeData.cooldownTime
                            sessionStorage.setItem('nextRageModeTime', nextRageModeTime)

                            // Set a timer to deactivate Rage Mode after its duration
                            setTimeout(() => {
                                setIsRageMode(false)
                            }, rageModeData.rageDuration)
                        }
                        break
                    default:
                        break
                }
            } catch (error) {
                console.error('Error parsing WebSocket message:', error)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastMessage])

    useEffect(() => {
        if (coins !== prevCoins.current) {
            const increment = (coins - prevCoins.current) / 30
            let currentCoins = prevCoins.current

            const animateCoins = () => {
                currentCoins += increment
                if ((increment > 0 && currentCoins >= coins) || (increment < 0 && currentCoins <= coins)) {
                    currentCoins = coins
                }
                setDisplayedCoins(currentCoins)

                if (currentCoins !== coins) {
                    requestAnimationFrame(animateCoins)
                } else {
                    prevCoins.current = coins
                }
            }

            if (coins > prevCoins.current) {
                setIsCoinAnimating(true)
                setTimeout(() => setIsCoinAnimating(false), 500)
            }

            requestAnimationFrame(animateCoins)
        }
    }, [coins])

    useEffect(() => {
        const requestPlayerData = () => {
            const requestData = {
                operation: 'read_player',
                data: {
                    telegram_id: player.telegram_id,
                },
            }
            sendMessage(JSON.stringify(requestData))
        }

        const requestPlayerEnergy = () => {
            const requestData = {
                operation: 'read_player_energy',
                data: {
                    telegram_id: player.telegram_id,
                },
            }
            sendMessage(JSON.stringify(requestData))
        }

        const requestPlayerSoftTokens = () => {
            const requestData = {
                operation: 'read_player_soft_tokens',
                data: {
                    telegram_id: player.telegram_id,
                },
            }
            sendMessage(JSON.stringify(requestData))
        }

        const requestPlayerUpgrades = () => {
            fetchPlayerUpgrades()
        }

        if (player?.telegram_id) {
            requestPlayerUpgrades()
            requestPlayerData()
            requestPlayerEnergy()
            requestPlayerSoftTokens()

            return () => {
                stopEnergyUpdateInterval()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [player?.telegram_id])

    useEffect(() => {
        return () => {
            stopSendingClicks()
            stopEnergyUpdateInterval()
        }
    }, [])

    useEffect(() => {
        if (tutorial) return

        const requestPlayerData = () => {
            const requestData = {
                operation: 'read_player',
                data: {
                    telegram_id: player.telegram_id,
                },
            }
            sendMessage(JSON.stringify(requestData))
        }

        if (energy < maxEnergyValue) {
            stopEnergyUpdateInterval()
            return
        }

        if (energy === maxEnergyValue) {
            requestPlayerData()
            startEnergyUpdateInterval(requestPlayerData)
        }

        return () => {
            stopEnergyUpdateInterval()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [energy, player?.telegram_id])

    const handleFlyCoin = targetElement => {
        const coinIcon = document.querySelector('.coin-icon')
        if (coinIcon && targetElement) {
            const coinRect = coinIcon.getBoundingClientRect()
            const targetRect = targetElement.getBoundingClientRect()

            setFlyingCoin({
                startX: coinRect.left + window.scrollX + coinRect.width / 2,
                startY: coinRect.top + window.scrollY + coinRect.height / 2,
                endX: targetRect.left + window.scrollX + targetRect.width / 2,
                endY: targetRect.top + window.scrollY + targetRect.height / 2 - 20,
            })

            setTimeout(() => setFlyingCoin(null), 1000)
        }
    }

    const handleFlyCoins = targetElement => {
        if (storageTokens > 0) {
            const coinIcon = document.querySelector('.progress-table-wrapper')
            if (coinIcon && targetElement) {
                const coinRect = coinIcon.getBoundingClientRect()
                const targetRect = targetElement.getBoundingClientRect()

                const flyingCoinsArray = []
                for (let i = 0; i < 5; i++) {
                    setTimeout(() => {
                        flyingCoinsArray.push({
                            id: i,
                            startX: coinRect.left + window.scrollX + coinRect.width / 2,
                            startY: coinRect.top + window.scrollY + coinRect.height / 2,
                            endX: targetRect.left + window.scrollX + targetRect.width / 2,
                            endY: targetRect.top + window.scrollY + targetRect.height / 2 - 20,
                        })
                        setFlyingCoins([...flyingCoinsArray])
                    }, i * 100)

                    setTimeout(() => {
                        setFlyingCoins([])
                    }, 1500)
                }
            }
        }
    }

    const fetchPlayerUpgrades = () => {
        const requestData = {
            operation: 'get_player_upgrades',
            data: {
                telegram_id: player.telegram_id,
            },
        }
        sendMessage(JSON.stringify(requestData))
    }

    const handleTouchStart = e => {
        if (energy === 0) return
        sounds.tap.play()

        if (isCharacterAnimatingRef.current) {
            isCharacterAnimatingRef.current = false
            setTimeout(() => {
                isCharacterAnimatingRef.current = true
            }, 0)
        } else {
            isCharacterAnimatingRef.current = true
        }

        setActiveImage(prev => (prev === 0 ? 1 : 0))

        setTimeout(() => {
            isCharacterAnimatingRef.current = false
        }, 1000)

        const newTouches = Array.from(e.touches).filter(touch => !activeTouches.current.has(touch.identifier))

        newTouches.forEach(touch => {
            activeTouches.current.add(touch.identifier)
            const x = touch.clientX
            const y = touch.clientY
            handleCharacterClick(x, y)
        })

        setCoins(prevCoins =>
            !isRageMode
                ? prevCoins + newTouches.length * tapPower
                : prevCoins + rageModeData.clickMultiplier * (newTouches.length * tapPower),
        )

        setEnergy(prevEnergy => Math.max(prevEnergy - newTouches.length, 0))

        totalClicks.current += newTouches.length
        clickCount.current += newTouches.length

        if (!tutorial) startSendingClicks()

        if (tutorial && clickCount.current > 10) handleNextStep(7)
    }

    const handleTouchEnd = e => {
        Array.from(e.changedTouches).forEach(touch => {
            const touchId = touch.identifier

            if (activeTouches.current.has(touchId)) {
                activeTouches.current.delete(touchId)
            }
        })
    }

    const handleCharacterClick = (x, y) => {
        // Calculate clicks q-ty
        const now = Date.now()
        const secondsAgo = now - rageModeData.activationTime

        clickTimesRef.current = [...clickTimesRef.current, now].filter(time => time >= secondsAgo)

        if (clickTimesRef.current.length >= rageModeData.activationClicks && !isRageMode) {
            activateRageMode()
            clickTimesRef.current = []
        }

        setCoinAnimations(prevAnimations => {
            const newAnimations = [...prevAnimations, { id: nanoid(5), x, y, tapValue: isRageMode ? 2 * tapPower : tapPower }]
            return newAnimations.length > 20 ? newAnimations.slice(newAnimations.length - 20) : newAnimations
        })

        setShapeAnimations(prevAnimations => {
            const newShapes = [...prevAnimations, { id: nanoid(5), x, y, isTurboMode: isRageMode }]
            return newShapes.length > 2 ? newShapes.slice(newShapes.length - 2) : newShapes
        })
    }

    const sendClicksToServer = useCallback(() => {
        const clicksToSend = totalClicks.current - sentClicks.current

        if (clicksToSend > 0) {
            if (energy > 0 && energy - clicksToSend <= 0) {
                const clickData = {
                    operation: 'player_click',
                    data: {
                        telegram_id: player.telegram_id,
                        clicks_count: energy,
                    },
                }
                sendMessage(JSON.stringify(clickData))
                sentClicks.current = totalClicks.current
                setEnergy(0)
                stopSendingClicks()
                return
            }
            const clickData = {
                operation: 'player_click',
                data: {
                    telegram_id: player.telegram_id,
                    clicks_count: clicksToSend,
                },
            }
            sendMessage(JSON.stringify(clickData))
            sentClicks.current += clicksToSend
        }

        if (totalClicks.current === sentClicks.current) {
            stopSendingClicks()
        }
    }, [energy, player?.telegram_id, sendMessage])

    const startSendingClicks = useCallback(() => {
        stopEnergyUpdateInterval()
        if (!timerRef.current) {
            timerRef.current = setInterval(() => {
                sendClicksToServer()
            }, 500)
        }
    }, [sendClicksToServer])

    const activateRageMode = useCallback(() => {
        if (tutorial) return

        // Check cooldown time
        const nextRageModeTime = sessionStorage.getItem('nextRageModeTime')
        if (nextRageModeTime && Date.now() < parseInt(nextRageModeTime, 10)) {
            console.log('Rage Mode is on cooldown. Try again later.')
            return // Rage mode is on cooldown
        }

        sendClicksToServer() // Ensure all pending clicks are sent
        stopSendingClicks()

        const sendActivationRageMode = () => {
            const requestData = {
                operation: 'activate_rage_mode',
                data: {
                    telegram_id: player.telegram_id,
                },
            }
            sendMessage(JSON.stringify(requestData))
        }

        sendActivationRageMode()
        startSendingClicks()
    }, [player?.telegram_id, sendClicksToServer, sendMessage, startSendingClicks, tutorial])

    const stopSendingClicks = () => {
        if (timerRef.current) {
            clearInterval(timerRef.current)
            timerRef.current = null
            setShapeAnimations([])
            setCoinAnimations([])
        }
    }

    const startEnergyUpdateInterval = requestPlayerData => {
        if (!energyIntervalRef.current) {
            energyIntervalRef.current = startInterval(() => {
                requestPlayerData()
            }, 30000)
        }
    }

    const stopEnergyUpdateInterval = () => {
        stopInterval(energyIntervalRef)
    }

    const spendCoinsForUpgrade = upgradeCost => {
        setCoins(prevCoins => (prevCoins - upgradeCost < 0 ? prevCoins : prevCoins - upgradeCost))
    }

    const earnCoinsForSubscription = upgradeCost => {
        setCoins(prevCoins => prevCoins + upgradeCost)
    }

    const togglePopup = () => {
        if (tutorial) return
        if (isInfoMenuVisible || isAchieveMenuVisible) {
            setInfoMenuVisible(false)
            setAchieveMenuVisible(false)
        }
        sounds.close.play()
        setPopupVisible(!isPopupVisible)
    }

    const toggleAchieveMenu = () => {
        if (tutorial && step !== 16) return
        setAchieveMenuVisible(!isAchieveMenuVisible)
        setInfoMenuVisible(false)
        if (tutorial && step === 16) {
            handleNextStep()
        }
        sounds.close.play()
    }

    const toggleInfoMenu = (nextStep = false) => {
        if (tutorial && step !== 14 && !nextStep) return
        setInfoMenuVisible(!isInfoMenuVisible)
        setAchieveMenuVisible(false)

        if (tutorial && step === 14) {
            handleNextStep()
        }
        sounds.close.play()
    }

    const removeCoin = useCallback(id => {
        setCoinAnimations(prev => prev.filter(anim => anim.id !== id))
    }, [])

    return (
        <div className="page">
            <div className="container">
                <div
                    className={clsx('gameplay-wrapper', {
                        'move-bg-up': isInfoMenuVisible,
                        'high-tap-speed': isRageMode,
                    })}
                >
                    <div className="player-info">
                        <div className={clsx('level-item', { tutorial_active: step === 12 }, { non_active: tutorial })}>
                            <div className="account-wrapper" onClick={togglePopup}>
                                <div className={`selected-pet-img ${selectedCharacter}`}></div>
                            </div>
                            <h3>{levelProgress.current_lvl} LVL</h3>
                            <LvlProgress levelProgress={levelProgress} />
                        </div>
                        <Storage
                            player={player}
                            sendMessage={sendMessage}
                            storageTokens={storageTokens}
                            setStorageTokens={setStorageTokens}
                            handleFlyCoins={handleFlyCoins}
                            playerUpgrades={playerUpgrades}
                            step={step}
                            storageUpdTime={storageUpdTime}
                        />
                    </div>
                    <div className={clsx('game-field-wrapper', { 'move-up': isInfoMenuVisible || isAchieveMenuVisible })}>
                        <div
                            className={clsx('coins-energy-wrapper', {
                                'move-up': isInfoMenuVisible || isAchieveMenuVisible,
                            })}
                        >
                            <div
                                className={clsx(
                                    'coins-wrapper',
                                    { 'coin-animate': isCoinAnimating },
                                    { tutorial_active: step === 8 },
                                    { scaled: isInfoMenuVisible || isAchieveMenuVisible },
                                )}
                            >
                                <img src={coinImg} alt="Coin Icon" className="coin-icon" />
                                <h1>{displayedCoins > 0 ? formatValue(displayedCoins) : 0}</h1>
                            </div>
                            <div className={clsx('energy_info', { tutorial_active: step === 7 })}>
                                <div className="energy-wrapper">
                                    <div className="energy-item">
                                        <p className="yellow-text">
                                            {energy} / {maxEnergyValue}
                                        </p>
                                    </div>
                                </div>
                                <EnergyTimer
                                    energy={energy}
                                    setEnergy={setEnergy}
                                    maxEnergyValue={maxEnergyValue}
                                    sendMessage={sendMessage}
                                    player={player}
                                    tutorial={tutorial}
                                    nextUpdateTime={nextUpdateTime}
                                />
                            </div>
                        </div>

                        {coinAnimations.map(anim => (
                            <CoinAnimation
                                key={anim.id}
                                x={anim.x}
                                y={anim.y}
                                removeCoin={() => removeCoin(anim.id)}
                                tapValue={anim.tapValue}
                            />
                        ))}

                        {shapeAnimations.map(anim => (
                            <FlyingShapes
                                key={anim.id}
                                x={anim.x}
                                y={anim.y}
                                isTurboMode={anim.isTurboMode}
                                character={player?.character.name}
                            />
                        ))}

                        <div
                            className={clsx('selected-character-display', {
                                'move-up': isInfoMenuVisible,
                                hidden: isPopupVisible,
                            })}
                        >
                            {/* <div>
                                <video src={myVideo} autoPlay loop muted style={{ width: '200px', height: 'auto' }} />
                            </div> */}
                            <img src={coinGame} className="coin-game" alt="Coin Game" />

                            {selectedCharacter ? (
                                <>
                                    <div
                                        className={clsx(
                                            {
                                                [activeImage === 0 ? 'active' : 'inactive']: true,
                                            },
                                            { step_five: step === 5 },
                                            {
                                                small: isInfoMenuVisible,
                                            },
                                        )}
                                    >
                                        <img
                                            src={characterImages[selectedCharacter].idle}
                                            alt={selectedCharacter}
                                            className={clsx('character', {
                                                'idle-character': !isCharacterAnimatingRef.current,
                                            })}
                                        />
                                        {/* <video
                                            src={characterImages[selectedCharacter].idle}
                                            autoPlay
                                            loop
                                            muted
                                            className={clsx('character', {
                                                'idle-character': !isCharacterAnimatingRef.current,
                                            })}
                                        /> */}
                                        <img
                                            src={characterImages[selectedCharacter].animate}
                                            alt={selectedCharacter}
                                            className={clsx('character', {
                                                'animate-character': isCharacterAnimatingRef.current,
                                            })}
                                        />
                                        {/* <video
                                            src={characterImages[selectedCharacter].animate}
                                            autoPlay
                                            loop
                                            muted
                                            className={clsx('character', {
                                                'animate-character': isCharacterAnimatingRef.current,
                                            })}
                                        /> */}
                                    </div>
                                    <div
                                        className={clsx(
                                            {
                                                [activeImage === 1 ? 'active' : 'inactive']: true,
                                            },
                                            { step_five: step === 5 },
                                            {
                                                small: isInfoMenuVisible,
                                            },
                                        )}
                                    >
                                        {/* <video
                                            src={characterImages[selectedCharacter].idle}
                                            autoPlay
                                            loop
                                            muted
                                            className={clsx('character', {
                                                'idle-character': !isCharacterAnimatingRef.current,
                                            })}
                                        />
                                        <video
                                            src={characterImages[selectedCharacter].animate}
                                            autoPlay
                                            loop
                                            muted
                                            className={clsx('character', {
                                                'animate-character': isCharacterAnimatingRef.current,
                                            })}
                                        /> */}
                                        <img
                                            src={characterImages[selectedCharacter].idle}
                                            alt={selectedCharacter}
                                            className={clsx('character', {
                                                'idle-character': !isCharacterAnimatingRef.current,
                                            })}
                                        />
                                        <img
                                            src={characterImages[selectedCharacter].animate}
                                            alt={selectedCharacter}
                                            className={clsx('character', {
                                                'animate-character ': isCharacterAnimatingRef.current,
                                            })}
                                        />
                                    </div>
                                </>
                            ) : (
                                <p>No character selected</p>
                            )}
                            <div
                                className={clsx('clicked-images-wrapper', { 'clicked-images-wrapper--inactive': tutorial && step !== 5 })}
                                onTouchStart={handleTouchStart}
                                onTouchEnd={handleTouchEnd}
                            >
                                <img
                                    src={Hand}
                                    width={70}
                                    alt="Hand"
                                    className={clsx('hand_click_img', { hand_click_hidden: step !== 5 })}
                                />
                            </div>
                        </div>
                    </div>
                    <BottomMenu
                        isAchieveMenuVisible={isAchieveMenuVisible}
                        isInfoMenuVisible={isInfoMenuVisible}
                        toggleInfoMenu={toggleInfoMenu}
                        toggleAchieveMenu={toggleAchieveMenu}
                        step={step}
                        tutorial={tutorial}
                    />
                </div>
            </div>

            {(isPopupVisible || step === 13) && (
                <LevelInfo
                    closePopup={togglePopup}
                    selectedCharacter={selectedCharacter}
                    player={player}
                    level={levelProgress.current_lvl}
                    step={step}
                    levelProgress={levelProgress}
                    tutorial={tutorial}
                />
            )}

            {isAchieveMenuVisible && (
                <AchieveMenu
                    closeMenu={toggleAchieveMenu}
                    telegramId={player?.telegram_id}
                    earnCoinsForSubscription={earnCoinsForSubscription}
                    openInfoMenu={toggleInfoMenu}
                    step={step}
                    handleNextStep={handleNextStep}
                />
            )}

            {isInfoMenuVisible && (
                <InfoMenu
                    closeMenu={toggleInfoMenu}
                    telegramId={player?.telegram_id}
                    playerUpgrades={tutorial ? upgradesTestArray : playerUpgrades}
                    fetchPlayerUpgrades={fetchPlayerUpgrades}
                    spendCoinsForUpgrade={spendCoinsForUpgrade}
                    coins={coins}
                    selectedCharacter={selectedCharacter}
                    handleFlyCoin={handleFlyCoin}
                    disabledButtons={flyingCoin}
                    openAchieveMenu={toggleAchieveMenu}
                    step={step}
                    handleNextStep={handleNextStep}
                />
            )}
            <FlyingCoins flyingCoin={flyingCoin} flyingCoins={flyingCoins} />

            <AchieveMenu
                closeMenu={toggleAchieveMenu}
                telegramId={player?.telegram_id}
                earnCoinsForSubscription={earnCoinsForSubscription}
                openInfoMenu={toggleInfoMenu}
                step={step}
                handleNextStep={handleNextStep}
                hidden
            />
        </div>
    )
}

export default Gameplay
