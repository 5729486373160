export const referralData = [
    { level: 1, invited: 1, scoga: 3000 },
    { level: 2, invited: 3, scoga: 5000 },
    { level: 3, invited: 5, scoga: 10000 },
    { level: 4, invited: 10, scoga: 20000 },
    { level: 5, invited: 20, scoga: 50000 },
    { level: 6, invited: 30, scoga: 100000 },
    { level: 7, invited: 50, scoga: 200000 },
    { level: 8, invited: 100, scoga: 300000 },
    { level: 9, invited: 150, scoga: 500000 },
    { level: 10, invited: 300, scoga: 1000000 },
]
