const tutorialStepsText = Object.freeze({
    step1: 'To start\nplaying, first\nsubscribe.',
    step2: "Now let's\nplay and earn!",
    step3: 'At first, choose your friend.',
    step4: 'Now it’s time to choose your companion!',
    step5: 'Start earning\nby tapping on the pet',
    step6: 'Welcome to\nthe 6 step!',
    step7: "Energy for pet's tap",
    step8: 'Your COGA points that you can\nexchange for real money',
    // step9: 'From the moment you\nenter the game,\ncoins are automatically\ncredited to your storage\n(offline tapper).\nThe system works on\nthe principle of\n100 coins per minute,\nand the coin\naccumulation rate per\nsecond can be upgraded.',
    // step10: 'There is also a storage\nlimit, which can\nbe increased as well.\n\nThe goal of the storage\nsystem is to increase\nthe frequency of\nplayer logins so\nthey can collect coins\nfrom their storage.',
    // step11: 'The time it takes to\nfill the storage depends\non two factors:\n- The rate of coins\n  per minute\n  (which can be upgraded)\n- The storage size\n  (which can be upgraded)\n\nTo collect the coins,\nsimply log in and\ntap on the storage.',
    step9: 'There is also a storage\nlimit, which can be increased as well.\n\nThe goal of the storage system\nis to increase the frequency of player logins\nso they can collect coins from their storage.',
    step10: 'From the moment you enter the game,\ncoins are automatically credited\nto your storage (offline tapper).\n\nThe system works on the principl\nof 100 coins per minute, and the coin\naccumulation rate per second can be upgraded.',
    step11: 'The time it takes to fill the\nstorage depends on two factors:\n\n1. The rate of coins per minute\n(which can be upgraded)\n\n2. The storage size (which can be upgraded)\n\nTo collect the coins, simply log in\nand tap on the storage.',
    step12: 'Your level and extra bonuses',
    step13: 'Invite friends.\nRank up.\nEarn even more.',
    step14: 'Welcome to\nthe 12 step!',
    step15: 'Upgrade your account\nto be the coolest and richest!',
    step16: 'Welcome to\nthe 14 step!',
    step17: "Don't forget\nabout tasks that\nwill give you a turbo boost.",
    step18: 'Great! Now set your nickname\nand start earning!',
})

export default tutorialStepsText
