export const characterImages = Object.freeze({
    main: {
        idle: require('../assets/gif_characters/Tiger_Idle-min.gif'),
        animate: require('../assets/gif_characters/Tiger_Tap-min.gif'),
    },
    pet1: {
        animate: require('../assets/gif_characters/Tarsius_Tap-min.gif'),
        idle: require('../assets/gif_characters/Tarsius_Idle-min.gif'),
    },
    pet2: {
        idle: require('../assets/gif_characters/Peafowl_Idle-min.gif'),
        animate: require('../assets/gif_characters/Peafowl_Tap-min.gif'),
    },
    pet3: {
        idle: require('../assets/gif_characters/Capybara_Idle-min.gif'),
        animate: require('../assets/gif_characters/Capybara_Tap-min.gif'),
    },
    pet4: {
        idle: require('../assets/gif_characters/Monkey_Idle-min.gif'),
        animate: require('../assets/gif_characters/Monkey_Tap-min.gif'),
    },
})

// export const characterImages = Object.freeze({
//     main: {
//         idle: require('assets/test/Tiger_Idle-min.webm'),
//         animate: require('assets/test/Tiger_Tap-min.webm'),
//     },
//     pet1: {
//         animate: require('assets/test/Tarsius_Tap-min.webm'),
//         idle: require('assets/test/Tarsius_Idle-min.webm'),
//     },
//     pet2: {
//         idle: require('assets/test/Peafowl_Idle-min.webm'),
//         animate: require('assets/test/Peafowl_Tap-min.webm'),
//     },
//     pet3: {
//         idle: require('assets/test/Capybara_Idle-min.webm'),
//         animate: require('assets/test/Capybara_Tap-min.webm'),
//     },
//     pet4: {
//         idle: require('assets/test/Monkey_Idle-min.webm'),
//         animate: require('assets/test/Monkey_Tap-min.webm'),
//     },
// })
