import React, { useState, useEffect } from 'react'
import { getTop100Players, getTop100PlayersByTelegramId, getLocalUserPosition, getGlobalUserPosition } from 'api/playersApi'
import './RankInfo.css'
import MedalOne from 'images/icons/medal_1.png'
import MedalTwo from 'images/icons/medal_2.png'
import MedalThree from 'images/icons/medal_3.png'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { formatValue } from 'utils'

import { clsx } from 'clsx'

const RankInfo = ({ closeRankInfo, telegram_id, player_nickname }) => {
    const [activeTab, setActiveTab] = useState('tab1')
    const [globalData, setGlobalData] = useState([])
    const [localData, setLocalData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [timeLeft, setTimeLeft] = useState(3600)

    useEffect(() => {
        const fetchAllData = async () => {
            try {
                setLoading(true)

                const [globalResponse, localResponse] = await Promise.all([
                    getTop100Players(),
                    telegram_id ? getTop100PlayersByTelegramId(telegram_id) : Promise.resolve(null),
                ])

                const globalPlayers = Array.isArray(globalResponse) ? globalResponse : Object.values(globalResponse)

                const globalUser =
                    player_nickname && !globalPlayers.some(player => player.nick_name === player_nickname)
                        ? await getGlobalUserPosition(player_nickname)
                        : null

                const finalGlobalData = globalUser ? [...globalPlayers, globalUser] : globalPlayers

                setGlobalData(finalGlobalData)

                const localPlayers = localResponse?.top_100_players ? Object.values(localResponse.top_100_players) : []

                const localUser =
                    player_nickname && !localPlayers.some(player => player.nick_name === player_nickname)
                        ? await getLocalUserPosition(player_nickname)
                        : null

                const finalLocalData = localUser ? [...localPlayers, localUser] : localPlayers

                setLocalData(finalLocalData)
            } catch (err) {
                setError(err.message)
            } finally {
                setLoading(false)
            }
        }

        fetchAllData()
    }, [telegram_id, player_nickname])

    useEffect(() => {
        if (!loading) {
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'))
            }, 0)
        }
    }, [loading])

    useEffect(() => {
        const calculateTimeLeft = () => {
            const currentTime = new Date()
            const nextHour = new Date()
            nextHour.setMinutes(0, 0, 0)
            nextHour.setHours(currentTime.getHours() + 1)
            const diffInSeconds = Math.floor((nextHour - currentTime) / 1000)
            setTimeLeft(diffInSeconds)
        }

        calculateTimeLeft()

        const interval = setInterval(() => {
            setTimeLeft(prevTime => {
                if (prevTime <= 1) {
                    calculateTimeLeft()
                    return 3600
                }
                return prevTime - 1
            })
        }, 1000)

        return () => clearInterval(interval)
    }, [])

    const formatTime = seconds => {
        const hours = Math.floor(seconds / 3600)
        const minutes = Math.floor((seconds % 3600) / 60)
        const secs = seconds % 60
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`
    }

    return (
        <div className="rang-info-overlay">
            <div className="rang-info-content">
                <img src={require('images/leaf-frame-popup.webp')} alt="" className="level-leaf-image" />
                <div className="close-btn" onClick={closeRankInfo}>
                    <img src={require('images/icons/close.png')} alt="Close" className="close-icon" />
                </div>

                <div className="tabs">
                    <button className={activeTab === 'tab1' ? 'active-tab' : ''} onClick={() => setActiveTab('tab1')}>
                        Friends
                    </button>
                    <button className={activeTab === 'tab2' ? 'active-tab' : ''} onClick={() => setActiveTab('tab2')}>
                        Global
                    </button>
                </div>

                <div className="table-content">
                    <div className="table_header">
                        <div>RANK</div>
                        <div>NICKNAME</div>
                        <div>TAPS</div>
                    </div>
                    <div className="rank_table">
                        <div className="rank_table_body">
                            {loading
                                ? Array.from({ length: 5 }).map((_, index) => (
                                      <div key={`skeleton-${index}`} className="skeleton-row rank_table_row">
                                          <div>
                                              <Skeleton width={30} height={30} />
                                          </div>
                                          <div>
                                              <Skeleton circle={true} width={40} height={40} />
                                          </div>
                                          <div>
                                              <Skeleton width={100} height={30} />
                                          </div>
                                          <div>
                                              <Skeleton width={50} height={30} />
                                          </div>
                                      </div>
                                  ))
                                : (activeTab === 'tab1' && localData.length > 0 ? localData : globalData).map(item => (
                                      <div
                                          key={item.rank}
                                          className={clsx('rank_table_row', { current_user: item.nick_name === player_nickname })}
                                      >
                                          <div className={clsx('rank_table_cell', { 'rank_table_cell--small_font': item.rank >= 10000 })}>
                                              {item.rank === 1 ? (
                                                  <img src={MedalOne} alt="Gold Medal" className="medal-icon" />
                                              ) : item.rank === 2 ? (
                                                  <img src={MedalTwo} alt="Silver Medal" className="medal-icon" />
                                              ) : item.rank === 3 ? (
                                                  <img src={MedalThree} alt="Bronze Medal" className="medal-icon" />
                                              ) : item.rank <= 100 ? (
                                                  item.rank
                                              ) : (
                                                  '>100'
                                              )}
                                          </div>
                                          <div>
                                              <div className="pet-icon">
                                                  <div className={`selected-table-pet-icon ${item.character}`} />
                                              </div>
                                          </div>
                                          <div className="user_name_cell">
                                              {item.nick_name.length > 10
                                                  ? item.nick_name.substring(0, 7) + '...'
                                                  : item.nick_name || item.username || 'No Nickname'}
                                          </div>
                                          <div className="td-end">{item.total_clicks >= 0 ? formatValue(item.total_clicks) : '-'}</div>
                                      </div>
                                  ))}
                        </div>
                    </div>
                </div>

                <div className="time-left-wrapper">
                    <p className="timer-update-text">TIME TO UPDATE: {formatTime(timeLeft)}</p>
                </div>
            </div>
        </div>
    )
}

export default RankInfo
