import './LevelProgressBar.css'

const LevelProgressBar = ({ levelProgress, tutorial }) => {
    const progressPercentage = tutorial
        ? 45
        : levelProgress.player_total_clicks && levelProgress.clicks_to_next_lvl > 0
        ? Math.min(
              Math.max(
                  Math.floor(
                      ((levelProgress.player_total_clicks - levelProgress.clicks_needed_for_current_lvl) /
                          (levelProgress.clicks_to_next_lvl - levelProgress.clicks_needed_for_current_lvl)) *
                          100,
                  ),
                  0,
              ),
              100,
          )
        : levelProgress && levelProgress.player_total_clicks && !levelProgress.clicks_to_next_lvl
        ? 100
        : 0

    return (
        <div className="level-progress-bar">
            <div className="level-progress-track ">
                <div className="level-progress-indicator" style={{ transform: `translateX(${progressPercentage - 100}%)` }} />
                <div className="level-progress-value-wrapper">
                    <p> {progressPercentage}%</p>
                </div>
            </div>
        </div>
    )
}

export default LevelProgressBar
