// Object containing constants for Rage Mode settings
const rageModeData = Object.freeze({
    activationClicks: 40, // Number of clicks required to activate Rage Mode
    activationTime: 10000, // Time frame in milliseconds within which clicks must be made
    rageDuration: 30000, // Duration of Rage Mode in milliseconds
    clickMultiplier: 2, // Multiplier for clicks during Rage Mode
    cooldownTime: 1800000, // Cooldown time for Rage Mode in milliseconds (30 minutes)
})

export default rageModeData
