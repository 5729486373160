import React, { useState, useEffect, memo } from 'react'
import { calculateTimeToNextTick, formatTime } from 'utils'

const EnergyTimer = ({ energy, maxEnergyValue, sendMessage, player, tutorial, nextUpdateTime }) => {
    const [totalTimeLeft, setTotalTimeLeft] = useState(() => calculateTimeToNextTick(nextUpdateTime))

    useEffect(() => {
        if (tutorial) return
        if (nextUpdateTime <= new Date().getTime() / 1000) {
            sendMessage(
                JSON.stringify({
                    operation: 'read_player_energy',
                    data: {
                        telegram_id: player.telegram_id,
                    },
                }),
            )
        }
    }, [player?.telegram_id, sendMessage, totalTimeLeft, tutorial, nextUpdateTime])

    useEffect(() => {
        if (energy < maxEnergyValue && nextUpdateTime) {
            const timeToNextTick = calculateTimeToNextTick(nextUpdateTime)
            setTotalTimeLeft(timeToNextTick)
            const timer = setInterval(() => {
                setTotalTimeLeft(prev => {
                    if (prev === 1) {
                        if (energy + 1 === maxEnergyValue) {
                            return 0
                        }
                        return 0
                    } else {
                        return prev - 1
                    }
                })
            }, 1000)

            return () => clearInterval(timer)
        }
    }, [energy, maxEnergyValue, sendMessage, player, nextUpdateTime])

    return <p className="timer-text">{energy < maxEnergyValue ? formatTime(totalTimeLeft) : '00:00'}</p>
}

export default memo(EnergyTimer)
