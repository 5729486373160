// import { getUTCSeconds } from './getUTCSeconds'

// export const calculateTimeToNextTick = () => {
//     const currentSeconds = getUTCSeconds()
//     if (currentSeconds < 30) {
//         return 30 - currentSeconds
//     } else {
//         return 60 - currentSeconds
//     }
// }

export const calculateTimeToNextTick = nextUpdateTime => {
    const currentTime = Date.now()
    return Math.max(Math.floor((nextUpdateTime * 1000 - currentTime) / 1000), 0)
}
