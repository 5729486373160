import React from 'react'
import clsx from 'clsx'
import LeftMenuIcon from 'images/icons/level_up.png'
import RightMenuIcon from 'images/icons/calendar.png'
import Hand from 'assets/Hand.png'
import './BottomMenu.css'

const BottomMenu = ({ toggleInfoMenu, toggleAchieveMenu, step, tutorial }) => {
    return (
        <div className="bottom-info">
            <div className={clsx('menu-btn', { non_active: tutorial && step !== 14 }, { on_top: step === 14 })} onClick={toggleInfoMenu}>
                <img src={LeftMenuIcon} alt="Menu" className="menu-icon" />
                <img
                    src={Hand}
                    width={70}
                    alt="Hand"
                    className={clsx('hand_menu_img', { hand_click_info_menu: step === 14 }, { hand_click_info_menu_hidden: step !== 14 })}
                />
            </div>
            <div className={clsx('menu-btn', { non_active: tutorial && step !== 16 }, { on_top: step === 16 })} onClick={toggleAchieveMenu}>
                <img src={RightMenuIcon} alt="Menu" className="menu-icon" />
                <img
                    src={Hand}
                    width={70}
                    alt="Hand"
                    className={clsx(
                        'hand_menu_img',
                        { hand_click_achieve_menu: step === 16 },
                        { hand_click_achieve_menu_hidden: step !== 16 },
                    )}
                />
            </div>
        </div>
    )
}

export default BottomMenu
