const socialMediaRewardsForTutorial = Object.freeze({
    instagram_poomb: {
        subscribed: false,
        reward_obtained: true,
        tokens: 400,
        link: 'https://www.instagram.com/poomb.creatures/',
    },
    instagram_comet_space: {
        subscribed: true,
        reward_obtained: false,
        tokens: 400,
        link: 'https://www.instagram.com/cometspace_company/?igsh=MTd6eTUzbTdiODdwZg%3D%3D',
    },
    x_poomb: {
        subscribed: true,
        reward_obtained: true,
        tokens: 800,
        link: 'https://x.com/Poomb_Creatures',
    },
    x_comet_space: {
        subscribed: false,
        reward_obtained: true,
        tokens: 800,
        link: 'https://x.com/CometSpace_Dev',
    },
    tiktok_poomb: {
        subscribed: true,
        reward_obtained: false,
        tokens: 400,
        link: 'https://www.tiktok.com/@poomb.app',
    },
    tiktok_comet_space: {
        subscribed: false,
        reward_obtained: false,
        tokens: 400,
        link: 'https://www.tiktok.com/@comet_space',
    },
    facebook_poomb: {
        subscribed: true,
        reward_obtained: false,
        tokens: 400,
        link: 'https://www.facebook.com/people/Poomb-Creatures-Community/61561848287998/',
    },
    facebook_comet_space: {
        subscribed: false,
        reward_obtained: true,
        tokens: 400,
        link: 'https://www.facebook.com/people/CometSpace-Company/61563560422100/',
    },
    linkedin_comet_space: {
        subscribed: true,
        reward_obtained: false,
        tokens: 400,
        link: 'https://www.linkedin.com/company/cometspace',
    },
    youtube_comet_space: {
        subscribed: false,
        reward_obtained: false,
        tokens: 800,
        link: 'https://www.youtube.com/@CometaSpace',
    },
    telegram_comet_space: {
        subscribed: true,
        reward_obtained: true,
        tokens: 1200,
        link: 'https://t.me/comet_space_group',
    },
    discord_poomb: {
        subscribed: false,
        reward_obtained: false,
        tokens: 800,
        link: 'https://discord.gg/xrbtCHuF',
    },
    website_comet_space_visited: {
        subscribed: true,
        reward_obtained: false,
        tokens: 100,
        link: 'https://www.cometspace.net/',
    },
    website_poomb_visited: {
        subscribed: false,
        reward_obtained: true,
        tokens: 100,
        link: 'https://www.poomb.app/',
    },
})

export default socialMediaRewardsForTutorial
