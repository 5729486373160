import { useState, useEffect, useCallback } from 'react'

import Robot from 'assets/robot.webp'
import clsx from 'clsx'

import './Tutorial.css'
import tutorialStepsText from 'constants/TutorialText'
import TypingText from './TypingText/TypingText'
import PetSelectionStep from './PetSelectionStep/PetSelectionStep'
import PetTapStep from './PetTapStep/PetTapStep'
import { sounds } from 'utils'
import FormStep from './FormStep/FormStep'
import Start from 'components/Start/Start'

const Tutorial = ({ isSubscribed, selectCharacter, userId }) => {
    const [step, setStep] = useState(() => {
        const savedStep = localStorage.getItem('tutorialStep')
        const initialStep = savedStep && isSubscribed ? parseInt(savedStep, 10) : null

        if (initialStep !== null) {
            return initialStep
        } else if (isSubscribed) {
            return 2
        } else {
            return 1
        }
    })

    const [selectedCharacter, setSelectedCharacter] = useState(() => {
        return localStorage.getItem('selectedCharacter') || ''
    })

    useEffect(() => {
        localStorage.setItem('tutorialStep', step)
    }, [step])

    useEffect(() => {
        localStorage.setItem('selectedCharacter', selectedCharacter)
    }, [selectedCharacter])

    const handleNextStep = useCallback(
        (nextStep = null) => {
            console.log('nextStep:', nextStep)
            if (step !== 4 && step !== 14 && step !== 16) sounds.approve.play()

            if (nextStep) {
                setStep(nextStep)
                return
            }
            if (step < 18) {
                setStep(prevStep => {
                    return prevStep + 1
                })
                return
            }
            return
        },
        [step],
    )

    const handleCharacterSelection = useCallback(
        nameCharacter => {
            sounds.select.play()
            setSelectedCharacter(nameCharacter)
            handleNextStep()
        },
        [handleNextStep],
    )

    return (
        <div className="tutorial_container">
            {step === 1 && <Start tutorial={true} isSubscribed={isSubscribed} handleNextStep={handleNextStep} step={step} />}

            {step === 2 && <Start tutorial={true} isSubscribed={isSubscribed} handleNextStep={handleNextStep} />}

            {(step === 3 || step === 4) && (
                <PetSelectionStep handleNextStep={handleNextStep} handleSelectClick={handleCharacterSelection} step={step} />
            )}

            {step !== 1 && step !== 2 && step !== 3 && step !== 4 && step !== 18 && (
                <PetTapStep selectedCharacter={selectedCharacter} step={step} handleNextStep={handleNextStep} />
            )}

            {step === 18 && <FormStep selectCharacter={selectCharacter} userId={userId} selectedCharacter={selectedCharacter} />}

            {/* STEP 9 (9) - STORAGE - part 1 */}
            {/* STEP 10 - STORAGE - part 2*/}
            {/* STEP 11 - STORAGE - part 3*/}
            {/* STEP 12 (10) - PlayerLevel - extra level bonuses*/}
            {/* STEP 13 (11) - PlayerLevelMenu - invite friend*/}
            {/* STEP 14 (12) - BottomMenu - left part*/}
            {/* STEP 15 (13) - InfoMenu - upgrade button*/}
            {/* STEP 16  (14) - AchieveMenu - hand*/}
            {/* STEP 17 (15) - AchieveMenu-open - description*/}
            {/* STEP 18 (16) - Form  - nickname*/}

            <div
                className={clsx(
                    'tutorial_robot_text_wrapper',
                    { no_visible: step === 1 || step === 2 || step === 6 },
                    // {
                    //     'tutorial_robot_text_wrapper--raised':
                    //         step === 3 || step === 4 || step === 9 || step === 10 || step === 11 || step === 14 || step === 16,
                    // },
                    { 'tutorial_robot_text_wrapper--very-raised': step === 15 },
                    // { 'tutorial_robot_text_wrapper--lowered': step === 13 || step === 18 },
                    { 'tutorial_robot_text_wrapper--big': step === 9 || step === 10 || step === 11 },
                )}
            >
                <div className="tutorial_img_wrapper">
                    <img src={Robot} alt="Robot" width={200} className={clsx('tutorial_robot_img')} />
                </div>
                <div className={clsx('btn_text_container', { button_visible: step === 5 }, { 'btn_text_container--lowered': step === 13 })}>
                    <div
                        className={clsx('tutorial_text_wrapper', {
                            'tutorial_text_wrapper--big': step === 15,
                        })}
                    >
                        <TypingText
                            text={tutorialStepsText[`step${step}`]}
                            typingSpeed={30}
                            step={step} /* isTextVisible={showTutorText}  */
                        />
                    </div>
                    <div className={clsx('tutorial_button_wrapper')}>
                        <button
                            type="button"
                            onClick={() => handleNextStep()}
                            className={clsx('btn_try', {
                                hidden:
                                    step === 1 ||
                                    step === 2 ||
                                    step === 3 ||
                                    step === 4 ||
                                    step === 5 ||
                                    step === 14 ||
                                    step === 16 ||
                                    step === 18,
                            })}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tutorial
