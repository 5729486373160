import React, { useState, useEffect, memo } from 'react'
import './TypingText.css'
import clsx from 'clsx'

const TypingText = ({ text = '', step }) => {
    const renderedText = text.split('\n').map((line, idx) => (
        <React.Fragment key={idx}>
            {line}
            <br />
        </React.Fragment>
    ))

    return (
        <div>
            <p
                className={clsx(
                    'tutorial_text',
                    { small: step === 8 || step === 15 || step === 17 },
                    { very_small: step === 9 || step === 10 || step === 11 },
                    { not_visible: step === 14 || step === 16 },
                )}
            >
                {renderedText}
            </p>
        </div>
    )
}

export default memo(TypingText)
